import React from 'react'
import { Link } from 'gatsby'
import { Loading } from '@ecommerce/shared'
import styled from 'styled-components'
import { INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Wrapper, Container } from '../components/StaticPages'
import LayoutStaticPage from '../components/Layout/LayoutStaticPage'
import { RichTextJson } from '../graphql/contentfulTypes'
import useQuery from '../hooks/useQuery'
import { StaticPage } from '../types/PgPages'

const Content = styled(Container)`
  .loader {
    width: 84px;
    height: 80px;
    position: absolute;
    left: calc(50% - 42px);
    top: calc(50% - 80px);
  }
`

type QueryResult = {
  pgPage: {
    title: string
    slug: string
    template: {
      title: string
      preTitle: string
      description: string
      customHtml?: string
      content?: RichTextJson & {
        links: {
          assets: {
            hyperlink: {
              sys: {
                id: string
              }
              url: string
            }[]
          }
        }
      }
    }
  }
}

const StockCyberPage = ({ pageContext: { cyberOctoberId } }: StaticPage) => {
  const { data, loading, error } = useQuery<QueryResult>(
    `query StockCyberQuery($id: String!) {
    pgPage(id: $id) {
      title
      slug
      template {
        ... on TmStaticPage {
          title
          preTitle
          description
          customHtml
          content {
            json
            links {
              assets {
                hyperlink {
                  sys {
                    id
                  }
                  url
                }
              }
            }
          }
        }
      }
    }
  }`,
    { variables: { id: cyberOctoberId } },
  )

  const contentData = data?.pgPage
  const hyperLinks = data?.pgPage?.template?.content?.links?.assets?.hyperlink ?? []

  return (
    <LayoutStaticPage title={contentData?.title ?? ''}>
      <Wrapper>
        <Content>
          {loading || !contentData || error ? (
            <Loading className="loader" />
          ) : (
            <>
              <h4>{contentData.template.title}</h4>
              <h2>{contentData.title}</h2>
              <p>{contentData.template.preTitle}</p>
              <div className="content">
                {contentData?.template?.content?.json
                  ? documentToReactComponents(contentData.template.content.json, {
                      renderNode: {
                        [INLINES.ASSET_HYPERLINK]: (node, content) => {
                          const targetAssetId: string = node?.data?.target?.sys?.id ?? ''
                          const asset = hyperLinks?.find((link) => link.sys.id === targetAssetId)

                          return (
                            <Link to={asset?.url ?? '#'} target="_blank" rel="noopener noreferrer">
                              {content}
                            </Link>
                          )
                        },
                      },
                    })
                  : null}
              </div>
            </>
          )}
        </Content>
      </Wrapper>
    </LayoutStaticPage>
  )
}

export default StockCyberPage
